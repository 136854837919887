exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-index-en-js": () => import("./../../../src/templates/About/index.en.js" /* webpackChunkName: "component---src-templates-about-index-en-js" */),
  "component---src-templates-about-index-fr-js": () => import("./../../../src/templates/About/index.fr.js" /* webpackChunkName: "component---src-templates-about-index-fr-js" */),
  "component---src-templates-analytics-products-index-en-js": () => import("./../../../src/templates/AnalyticsProducts/index.en.js" /* webpackChunkName: "component---src-templates-analytics-products-index-en-js" */),
  "component---src-templates-careers-index-en-js": () => import("./../../../src/templates/Careers/index.en.js" /* webpackChunkName: "component---src-templates-careers-index-en-js" */),
  "component---src-templates-careers-index-fr-js": () => import("./../../../src/templates/Careers/index.fr.js" /* webpackChunkName: "component---src-templates-careers-index-fr-js" */),
  "component---src-templates-contact-index-en-js": () => import("./../../../src/templates/Contact/index.en.js" /* webpackChunkName: "component---src-templates-contact-index-en-js" */),
  "component---src-templates-contact-index-fr-js": () => import("./../../../src/templates/Contact/index.fr.js" /* webpackChunkName: "component---src-templates-contact-index-fr-js" */),
  "component---src-templates-decision-tools-index-en-js": () => import("./../../../src/templates/DecisionTools/index.en.js" /* webpackChunkName: "component---src-templates-decision-tools-index-en-js" */),
  "component---src-templates-decision-tools-index-fr-js": () => import("./../../../src/templates/DecisionTools/index.fr.js" /* webpackChunkName: "component---src-templates-decision-tools-index-fr-js" */),
  "component---src-templates-degree-hub-index-en-js": () => import("./../../../src/templates/DegreeHub/index.en.js" /* webpackChunkName: "component---src-templates-degree-hub-index-en-js" */),
  "component---src-templates-degree-hub-index-fr-js": () => import("./../../../src/templates/DegreeHub/index.fr.js" /* webpackChunkName: "component---src-templates-degree-hub-index-fr-js" */),
  "component---src-templates-generic-content-index-en-js": () => import("./../../../src/templates/GenericContent/index.en.js" /* webpackChunkName: "component---src-templates-generic-content-index-en-js" */),
  "component---src-templates-generic-content-index-fr-js": () => import("./../../../src/templates/GenericContent/index.fr.js" /* webpackChunkName: "component---src-templates-generic-content-index-fr-js" */),
  "component---src-templates-generic-product-index-en-js": () => import("./../../../src/templates/GenericProduct/index.en.js" /* webpackChunkName: "component---src-templates-generic-product-index-en-js" */),
  "component---src-templates-generic-product-index-fr-js": () => import("./../../../src/templates/GenericProduct/index.fr.js" /* webpackChunkName: "component---src-templates-generic-product-index-fr-js" */),
  "component---src-templates-post-index-en-js": () => import("./../../../src/templates/Post/index.en.js" /* webpackChunkName: "component---src-templates-post-index-en-js" */),
  "component---src-templates-post-index-fr-js": () => import("./../../../src/templates/Post/index.fr.js" /* webpackChunkName: "component---src-templates-post-index-fr-js" */),
  "component---src-templates-survey-products-index-en-js": () => import("./../../../src/templates/SurveyProducts/index.en.js" /* webpackChunkName: "component---src-templates-survey-products-index-en-js" */),
  "component---src-templates-survey-products-index-fr-js": () => import("./../../../src/templates/SurveyProducts/index.fr.js" /* webpackChunkName: "component---src-templates-survey-products-index-fr-js" */),
  "component---src-templates-updates-index-en-js": () => import("./../../../src/templates/Updates/index.en.js" /* webpackChunkName: "component---src-templates-updates-index-en-js" */),
  "component---src-templates-updates-index-fr-js": () => import("./../../../src/templates/Updates/index.fr.js" /* webpackChunkName: "component---src-templates-updates-index-fr-js" */),
  "component---src-templates-vote-compass-index-en-js": () => import("./../../../src/templates/VoteCompass/index.en.js" /* webpackChunkName: "component---src-templates-vote-compass-index-en-js" */),
  "component---src-templates-vote-compass-index-fr-js": () => import("./../../../src/templates/VoteCompass/index.fr.js" /* webpackChunkName: "component---src-templates-vote-compass-index-fr-js" */)
}

